import { CloseIcon } from '@components/icons';
import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';

type Props = {
  baseUrl: string;
  locale: SiteLocale;
};

export const FeedbackUser = ({ locale, baseUrl }: Props) => {
  const { state, dispatch } = useAppContext();
  const { feedbackModalUser: result } = state;
  const isActive = result ? true : false;

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL_USER', data: false });
  };

  return (
    <div className={`dialog${isActive ? ' is-active' : ''} feedback-dialog`}>
      <div className="dialog__frame">
        <div className="dialog__header">
          <div className="dialog__close" onClick={handleClose}>
            <CloseIcon className="nav_color_icon" />
          </div>
        </div>

        <div className="dialog__body__content">
          <span className="dialog__body__content--title">
            {t(locale, 'welcome_back')} <b>{result?.customer?.email}</b>
          </span>
        </div>

        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            <button type="button" onClick={handleClose} className="feedback-dialog__buttons__cart">
              {t(locale, 'close')}
            </button>
            <a
              href={`${baseUrl}/${locale}/account`}
              className="feedback-dialog__buttons__close"
              onClick={handleClose}
            >
              {t(locale, 'go_to_account')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
