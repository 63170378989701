type Props = {
  width?: string;
  height?: string;
};
export const EyeHidden = ({ width, height }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 25 22.2"
      viewBox="0 0 25 22.2"
      width={width}
      height={height}
    >
      <path d="M.8 2.5l3.3 3.3C2.5 7 1.1 8.6.2 10.5c-.2.4-.2.9 0 1.3 2.4 4.6 7 7.7 12.3 7.7 1.6 0 3.1-.3 4.5-.8l3.3 3.3 1.6-1.6L2.4.9.8 2.5zm11.7 14.9c-3.5 0-6.3-2.8-6.3-6.3 0-.9.2-1.8.6-2.6l1.7 1.7c-.5 2.1.8 4.4 2.9 4.9.7.2 1.4.2 2 0l1.7 1.7c-.8.4-1.7.6-2.6.6zm1.1-10.3c-.4-.1-.7-.2-1.1-.2-.3 0-.6.1-.8.1l4.9 4.9c.4-2.1-.9-4.2-3-4.8z"></path>
      <path d="M24.8 10.5c-2.4-4.6-7-7.7-12.3-7.7-1.5 0-3 .3-4.3.7L10 5.4c.8-.3 1.6-.5 2.5-.5 3.5 0 6.3 2.8 6.3 6.3 0 .9-.2 1.7-.5 2.4l2.8 2.8c1.5-1.2 2.8-2.8 3.7-4.6.3-.5.3-.9 0-1.3z"></path>
    </svg>
  );
};
