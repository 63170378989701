type Props = {
  width?: string;
  height?: string;
  className?: string;
};

export const BackIcon = ({ width, height, className }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <mask
        id="a"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
      </mask>
      <g mask="url(#a)">
        <path
          fill="#263141"
          d="M6.667 14.667L0 8l6.667-6.667L7.85 2.517 2.367 8l5.483 5.483-1.183 1.184z"
        ></path>
      </g>
    </svg>
  );
};
