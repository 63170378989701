import { CloseIcon } from '@components/icons/CloseIcon';
import { SiteLocale } from '@graphql/generated';
import { useDebounce } from '@hooks/use-debounce';
import { searchContents } from '@lib/elastic';
import t from '@lib/locales';
import { useParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

type Props = {
  onClose: () => void;
};

export const SearchBar = ({ onClose }: Props) => {
  const params = useParams<{ lang: SiteLocale; market: string; currency: string }>();
  const locale = params.lang;
  const [results, setResults] = useState<
    (
      | {
          type: 'category' | 'page';
          title: string;
          href: string;
        }
      | {
          type: 'product';
          title: string;
          href: string;
          image: string;
        }
    )[]
  >([]);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const { debouncedValue } = useDebounce(searchValue, 500);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchDropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        showResults &&
        searchDropdownRef.current &&
        !searchDropdownRef.current.contains(e.target as Node) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(e.target as Node)
      ) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [showResults]);

  const search = async (query: string) => {
    if (!query) {
      setIsLoading(false);
      return setResults([]);
    }
    const market = params.market;
    const currency = params.currency;
    const response = await searchContents(market, currency, locale, query);
    const res = response.hits.map(({ _source }: any) => _source);
    setResults(res);
    if (!!res) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    search(debouncedValue.toString());
  }, [debouncedValue]);

  const categories = results.filter((r) => r.type === 'category');
  const pages = results.filter((r) => r.type === 'page');
  const products = results.filter((r) => r.type === 'product');

  return (
    <div className="nav__account__search_container">
      <div className="search-bar__input__icon search-bar__input__close" onClick={onClose}>
        <CloseIcon className="nav_color_icon" />
      </div>
      <div className="nav__account__search_element">
        <div className="search-bar__wrapper">
          <div className="search-bar__wrapper__input">
            {isLoading && (
              <div className="spinner-container">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            <input
              className="search-bar__input"
              type="text"
              ref={searchInputRef}
              value={searchValue}
              onChange={(e) => {
                setIsLoading(true);
                if (!showResults) {
                  setShowResults(true);
                }
                setSearchValue(e.target.value);
              }}
              placeholder={t(locale, 'search_product')}
              maxLength={128}
              autoComplete="off"
              autoFocus
            />
          </div>
        </div>
      </div>

      <div className="search-bar__dropdown">
        {showResults && !!results.length && (
          <>
            {!!products.length && (
              <>
                <div className="search-bar__group">
                  <h2 className="search-bar__group__title">{t(locale, 'product_basepath')}</h2>
                </div>
                <div className="search-bar__link">
                  {products.slice(0, 10).map(
                    (p, i) =>
                      p.type === 'product' && (
                        <a key={i} className="search-bar__link__a" href={p.href}>
                          {p.image && (
                            <div
                              className="search-bar__link__img"
                              style={{
                                backgroundImage: `url(${p.image.concat('?w=100')})`,
                              }}
                            ></div>
                          )}
                          <span className="search-bar__link__text">{p.title}</span>
                        </a>
                      )
                  )}
                </div>
              </>
            )}

            {!!categories.length && (
              <>
                <div
                  className={`search-bar__group ${
                    !!products.length ? 'search-bar__group-border' : ''
                  }`}
                >
                  <h2 className="search-bar__group__title">{t(locale, 'category_basepath')}</h2>
                </div>
                <div className="search-bar__category">
                  {categories.slice(0, 5).map((c, i) => (
                    <a
                      key={i}
                      className="search-bar__link search-bar__link__text search-bar__link--category"
                      href={c.href}
                      style={{ width: '100%' }}
                    >
                      {c.title}
                    </a>
                  ))}
                </div>
              </>
            )}

            {!!pages.length && (
              <>
                <div
                  className={`search-bar__group ${
                    !!products.length || !!categories.length ? 'search-bar__group-border' : ''
                  }`}
                >
                  <h2 className="search-bar__group__title">{t(locale, 'pages')}</h2>
                </div>
                <div className="search-bar__category">
                  {pages.slice(0, 5).map((p, i) => (
                    <a
                      key={i}
                      className="search-bar__link search-bar__link__text search-bar__link--page"
                      href={p.href}
                      style={{ width: '100%' }}
                    >
                      {p.title}
                    </a>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {results.length == 0 && searchValue.length > 0 && (
          <>
            {isLoading ? (
              <div className="search-bar__search--results">{t(locale, 'research_in_progress')}</div>
            ) : (
              <div className="search-bar__search--results">{t(locale, 'no_results')}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
