type Props = {
  width?: string;
  height?: string;
  className?: string;
};

export const SearchIcon = ({ width, height, className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width || '27'}
      height={height || '27'}
    >
      <defs>
        <path id="a" d="M0 0h12.471v12.472H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="13.5" cy="13.5" r="13.5"></circle>
        <g transform="translate(5.654 5.605)">
          <mask id="b" fill="#FFF">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            fill="#263141"
            d="M6.236 1.21A5.03 5.03 0 001.21 6.237a5.031 5.031 0 005.025 5.025 5.031 5.031 0 005.025-5.025A5.03 5.03 0 006.236 1.21m0 11.26A6.243 6.243 0 010 6.237 6.243 6.243 0 016.236 0a6.243 6.243 0 016.235 6.236 6.243 6.243 0 01-6.235 6.236"
            mask="url(#b)"
          ></path>
        </g>
        <path fill="#263141" d="M20.572 21.38l-5.13-5.13.857-.856 5.129 5.13z"></path>
      </g>
    </svg>
  );
};
