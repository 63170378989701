import { CategoryQuery, SiteQuery } from '@graphql/generated';
import { InternalLink } from './InternalLink';

type Props = {
  block: SiteQuery['privacy']['contentBlock'][0] | CategoryQuery['category']['description'][0];
};

export const ModularText = ({ block }: Props) => {
  const strippedString = (s: string) => {
    return (s || '').replace(/(<([^>]+)>)/gi, '');
  };

  switch (block.__typename) {
    case 'TitleBlockRecord':
      return (
        <h2
          className={`title_block ${block.cssClass}`}
          dangerouslySetInnerHTML={{ __html: block.titleContent }}
        />
      );
    case 'TextInlineRecord':
      return <span dangerouslySetInnerHTML={{ __html: strippedString(block.textContent) }} />;
    case 'NewParagraphRecord':
      return <p>{block.newParagraph}</p>;
    case 'InternalLinkRecord':
      return <InternalLink title={block.title} internalLink={block.page} className="text__link" />;
    default:
      return null;
  }
};
