import { UserIcon } from '@components/icons/UserIcon';
import { useAppContext } from '@lib/context/appContext';
import { getUserSession } from '@utils/session-storage';
import { useRouter } from 'next/navigation';
import { MouseEvent } from 'react';
type Props = {
  baseUrl: string;
  locale: string;
};

export const UserToggle = ({ baseUrl, locale }: Props) => {
  const router = useRouter();
  const { state, dispatch } = useAppContext();
  const { authModal: isOpen } = state;

  function toggle(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    const user = getUserSession();
    if (user) {
      router.push(`${baseUrl}/${locale}/account`);
    } else {
      dispatch({ type: 'TOGGLE_AUTH_MODAL', data: !isOpen });
    }
  }
  return (
    <div className="auth__nav">
      <a
        href={`${baseUrl}/${locale}/account`}
        className="auth__link"
        aria-label="Account"
        onClick={toggle}
      >
        <UserIcon width="28px" height="28px" />
      </a>
    </div>
  );
};
