import { MenuFragment, SiteLocale } from '@graphql/generated';
import { AltsType } from '@model/common';
import { Fragment, useState } from 'react';
import CountrySelector from './CountrySelector';
import { LanguageSelector } from './LanguageSelector';
import { NavbarItem } from './NavbarItem';

type Props = {
  locale: SiteLocale;
  baseUrl: string;
  alts?: AltsType;
  lang: string;
  menu: MenuFragment[];
  onOpenMenuChange: (isMenuOpened: boolean) => void;
};

export const Navbar = ({ locale, alts, baseUrl, lang, menu, onOpenMenuChange }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = (value: boolean) => {
    setOpenMenu(value);
    onOpenMenuChange(value);
  };

  return (
    <nav className="site-nav">
      <div className="site-nav__toggle" onClick={() => toggleMenu(true)}>
        <div className="site-nav__toggle__input__item"></div>
        <div className="site-nav__toggle__input__item"></div>
        <div className="site-nav__toggle__input__item"></div>
      </div>
      <div className={`site-nav__menu ${openMenu ? 'is-open' : ''}`}>
        <div className="site-nav__toggle__label" onClick={() => toggleMenu(false)}>
          <span className="site-nav__toggle__label__item"></span>
          <span className="site-nav__toggle__label__item"></span>
        </div>
        <ul className="site-nav__menu__list">
          {menu.map((slug, index) => {
            return (
              slug.parent === null && (
                <Fragment key={index}>
                  <NavbarItem
                    item={slug}
                    baseUrl={baseUrl}
                    lang={lang}
                    locale={locale}
                    onClose={() => toggleMenu(false)}
                  />
                </Fragment>
              )
            );
          })}
        </ul>
        <div className="site-nav__account">
          <CountrySelector locale={locale} />
          <LanguageSelector locale={locale} baseUrl={baseUrl} alts={alts} />
        </div>
      </div>
    </nav>
  );
};
