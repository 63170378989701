import { SiteLocale } from '@graphql/generated';

export const searchContents = async (
  market: string,
  currency: string,
  locale: SiteLocale,
  query: string
) => {
  const url = `/api/${market}/${currency}/${locale}/search/${query}`;
  const data = await fetch(url, { cache: 'force-cache', next: { tags: ['elasticsearch'] } });
  return data.json();
};
