import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { getCountryName } from '@lib/utils/clUtils';
import { State } from '@model/state';
import { ActionDispatch, AnyActionArg } from 'react';

type Props = {
  locale: SiteLocale;
};

const CountrySelector = ({ locale }: Props) => {
  const { state, dispatch } = useAppContext() as {
    state: State;
    dispatch: ActionDispatch<AnyActionArg>;
  };
  function showMarketSelector() {
    dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: true });
  }

  if (!state.hasMultipleCountries || !state.marketId) {
    return null;
  }

  return (
    <div className="change_market">
      <a
        href="#"
        title={t(locale, 'change_market')}
        // we cannot change the role change-market because of the styles of masi
        role="change-market"
        className="nav__menu__link"
        onClick={showMarketSelector}
      >
        <span className="change_market--title">{t(locale, 'change_market')}:</span>{' '}
        <span className="change_market--country">{getCountryName(locale, state.countryCode)}</span>
      </a>
    </div>
  );
};
export default CountrySelector;
