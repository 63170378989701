import { ResponsiveImageFragment } from '@graphql/generated';
import { SRCImage } from 'react-datocms';

type Props = {
  /** The actual response you get from a DatoCMS `responsiveImage` GraphQL query */
  data: ResponsiveImageFragment;
  /** Additional CSS className for root node */
  className?: string;
  /** Additional CSS rules to add to the root node */
  style?: React.CSSProperties;
  /**
   * When true, the image will be considered high priority. Lazy loading is automatically disabled, and fetchpriority="high" is added to the image.
   * You should use the priority property on any image detected as the Largest Contentful Paint (LCP) element. It may be appropriate to have multiple priority images, as different images may be the LCP element for different viewport sizes.
   * Should only be used when the image is visible above the fold.
   **/
  priority?: boolean;
};

export const DatoImage = ({ data, className, style, priority }: Props) => {
  return (
    <SRCImage
      data={data}
      imgClassName={className}
      imgStyle={{
        aspectRatio: 'revert-layer',
        maxWidth: 'revert-layer',
        width: 'revert-layer',
        height: 'revert-layer',
        ...style,
      }}
      priority={priority}
    />
  );
};
