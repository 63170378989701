import { CloseIcon } from '@components/icons/CloseIcon';
import { DatoImage } from '@components/image/DatoImage';
import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { ErrorIcon, SuccessIcon } from '..';

type Props = {
  baseUrl: string;
  locale: SiteLocale;
};

export const FeedbackDialog = ({ locale, baseUrl }: Props) => {
  const { state, dispatch } = useAppContext();
  const { feedbackModal: result } = state;
  const isActive = result ? true : false;

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL', data: false });
  };

  if (!result) {
    return null;
  }

  return (
    <div className={`dialog ${isActive ? 'is-active' : ''} feedback-dialog`}>
      <div className="dialog__frame">
        <div className="dialog__header">
          <div className="dialog__close" onClick={handleClose}>
            <CloseIcon className="nav_color_icon" />
          </div>
        </div>

        <div className="dialog__body">
          {result.success ? (
            <div className="feedback-dialog__success">
              <SuccessIcon width="30px" />
              <span>{t(locale, 'add_cart_success')}</span>
            </div>
          ) : (
            <div className="feedback-dialog__error">
              <ErrorIcon width="30px" />
              <span>{t(locale, 'add_cart_error')}</span>
            </div>
          )}
          <div className="feedback-dialog__product">
            <DatoImage data={result?.image} className="feedback-dialog__product__image" />
            <span className="feedback-dialog__product__name">{result?.name}</span>
            <span className="feedback-dialog__product__quantity">{` + ${result?.incQuantity}`}</span>
          </div>
        </div>

        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            {result.success && (
              <a href={`${baseUrl}/${locale}/cart`} className="feedback-dialog__buttons__cart">
                {t(locale, 'go_to_cart')}
              </a>
            )}
            <a onClick={handleClose} className="feedback-dialog__buttons__close">
              {t(locale, 'add_more_products')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
