import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { useRouter } from 'next/navigation';
import { CloseIcon } from '..';

type Props = {
  baseUrl: string;
  locale: SiteLocale;
};

export const FeedbackDialogQuantity = ({ locale, baseUrl }: Props) => {
  const { state, dispatch } = useAppContext();
  const { feedbackModalQuantity: result } = state;
  const isActive = result ? true : false;
  const router = useRouter();

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL_QUANTITY', data: false });
    router.push(`${baseUrl}/${locale}/cart`);
  };
  if (!result) {
    return null;
  }
  return (
    <div className={`dialog ${isActive ? 'is-active' : ''} feedback-dialog`}>
      <div className="dialog__frame">
        <div className="dialog__header">
          <div className="dialog__close" onClick={handleClose}>
            <CloseIcon className="nav_color_icon" />
          </div>
        </div>

        <div className="dialog__body">
          <div className="feedback-dialog__error">
            <span className="close-icon">{t(locale, 'check_cart')}</span>
          </div>
        </div>

        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            <a onClick={handleClose} className="feedback-dialog__buttons__close">
              {t(locale, 'close')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
