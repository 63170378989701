import { SiteLocale, SiteQuery } from '@graphql/generated';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { useAppContext } from '@lib/context/appContext';
import t, { tt } from '@lib/locales';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { ModularText } from '../contents/ModularText';

type Props = {
  client: AppCommerceLayerClient;
  locale: SiteLocale;
  onSuccess: any;
  privacy: SiteQuery['privacy'];
  subscribe: SiteQuery['subscribe'];
  profiling: SiteQuery['profiling'];
};

const Register = ({ client, locale, onSuccess, privacy, subscribe, profiling }: Props) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: false });

  const schema = z
    .object({
      email: z
        .string()
        .min(1, t(locale, 'field_required'))
        .email(t(locale, 'enter_valid_email'))
        .toLowerCase()
        .trim(),
      password: z.string().min(6, tt(locale, 'password_min_chars', { min: '6' })),
      policy_consent: z.boolean().refine((value) => value === true, t(locale, 'field_required')),
      subscribe_consent: z.boolean().optional(),
      profiling_consent: z.boolean().optional(),
    })
    .required({
      email: true,
      password: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [done, setDone] = useState(false);
  const { state } = useAppContext();
  const { baseUrl } = state;

  const createCustomer = async (user: Schema) => {
    const { subscribe_consent = false, profiling_consent = false } = user;
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      //@ts-expect-error strictNullChecks
      const response = await client.register(user, { language: locale }, subscribe_consent);

      //@ts-expect-error strictNullChecks
      await client.login(user);
      if (subscribe_consent) {
        await client.subscribeToProfilingOrNewsletter({
          email: user.email,
          metadata: { consent_type: 'marketing' },
        });
      }
      if (profiling_consent) {
        await client.subscribeToProfilingOrNewsletter({
          email: user.email,
          metadata: {
            consent_type: 'profiling',
          },
        });
      }
      // onSuccess();
      setDone(true);
    } catch (e: any) {
      errorMessage = e.response?.data?.errors[0].detail ?? e.message ?? 'error';
      if (errorMessage.indexOf('already') > -1) {
        errorMessage = t(locale, 'email_already_registered');
      } else if (errorMessage.indexOf('email') > -1) {
        errorMessage = t(locale, 'invalid_email');
      } else if (errorMessage.indexOf('password') > -1) {
        errorMessage = t(locale, 'invalid_password');
      } else {
        errorMessage = t(locale, 'generic_error');
      }
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  return (
    <div className="auth">
      {!done && <h4 className="auth__title"> {t(locale, 'register')}</h4>}

      {done && (
        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            <div className="feedback-dialog__buttons__text">{t(locale, 'register_success')}</div>
            <div className="feedback-dialog__buttons__display">
              <a
                href="#"
                onClick={() => {
                  onSuccess();
                }}
                className="feedback-dialog__buttons__cart"
              >
                {t(locale, 'close')}
              </a>
              <a
                href={`${baseUrl}/${locale}/account`}
                className="feedback-dialog__buttons__close"
                onClick={() => {
                  onSuccess();
                }}
              >
                {t(locale, 'go_to_account')}
              </a>
            </div>
          </div>
        </div>
      )}

      {ui.errorMessage && <div className="form__error">{ui.errorMessage}</div>}
      {!done && (
        <form onSubmit={handleSubmit(createCustomer)}>
          <div className="form__field">
            <input
              className="form__input"
              type="email"
              placeholder={t(locale, 'email')}
              {...register('email')}
            />
            {errors.email && <span className="form__error">{errors.email.message.toString()}</span>}
          </div>
          <div className="form__field">
            <input
              className="form__input"
              placeholder={t(locale, 'password')}
              type="password"
              autoComplete="off"
              {...register('password')}
            />
            {errors.password && (
              <span className="form__error">{errors.password.message.toString()}</span>
            )}
          </div>
          <div className="form__field boolean">
            {errors.policy_consent && (
              <span className="form__error">{errors.policy_consent.message.toString()}</span>
            )}
            <label className="form__label boolean">
              <input
                className="form__input boolean"
                type="checkbox"
                {...register('policy_consent')}
              />
              {privacy &&
                privacy.contentBlock.map((block, index) => {
                  return <ModularText key={index} block={block} />;
                })}
            </label>
          </div>
          <div className="form__field boolean">
            <label className="form__label boolean">
              <input
                className="form__input boolean"
                type="checkbox"
                {...register('subscribe_consent')}
              />
              {subscribe &&
                subscribe.contentBlock.map((block, index) => {
                  return <ModularText key={index} block={block} />;
                })}
            </label>
            {errors.subscribe_consent && (
              <span className="form__error">{errors.subscribe_consent.message.toString()}</span>
            )}
          </div>
          {profiling && profiling.showCheckbox && (
            <div className="form__field boolean">
              <label className="form__label boolean">
                <input
                  className="form__input boolean"
                  type="checkbox"
                  {...register('profiling_consent')}
                />
                {profiling &&
                  profiling.contentBlock.map((block, index) => {
                    return <ModularText key={index} block={block} />;
                  })}
              </label>
              {errors.profiling_consent && (
                <span className="form__error">{errors.profiling_consent.message.toString()}</span>
              )}
            </div>
          )}

          <div className="form__field">
            {!ui.loading && (
              <button className="button--primary" type="submit">
                {t(locale, 'register')}
              </button>
            )}
            {ui.loading && <div> {t(locale, 'loading')}</div>}
          </div>
        </form>
      )}
    </div>
  );
};

export default Register;
