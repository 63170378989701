type Props = {
  width?: string;
  height?: string;
};
export const Eye = ({ width, height }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 25 22.2"
      viewBox="0 0 25 22.2"
      width={width}
      height={height}
    >
      <path d="M24.8 10.5c-2.4-4.6-7-7.7-12.3-7.7S2.5 5.9.2 10.5c-.2.4-.2.9 0 1.3 2.4 4.6 7 7.7 12.3 7.7s10-3.1 12.3-7.7c.3-.5.3-.9 0-1.3zm-12.3 6.9c-3.5 0-6.3-2.8-6.3-6.3S9 4.9 12.5 4.9s6.3 2.8 6.3 6.3c0 3.4-2.8 6.2-6.3 6.2zm0-10.5c-.4 0-.7.1-1.1.2.7.9.5 2.2-.4 2.9-.7.5-1.7.5-2.5 0-.6 2.2.7 4.5 2.9 5.1 2.2.6 4.5-.7 5.1-2.9s-.7-4.5-2.9-5.1c-.4-.1-.7-.2-1.1-.2z"></path>
    </svg>
  );
};
