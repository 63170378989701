import { SiteLocale } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { CloseIcon } from '..';

type Props = {
  locale: SiteLocale;
};

export const PaymentErrorDialog = ({ locale }: Props) => {
  const { state, dispatch } = useAppContext();
  const { paymentErrorModal } = state;

  if (!paymentErrorModal) {
    return null;
  }

  const isActive = !!paymentErrorModal;

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_PAYMENT_ERROR_MODAL', data: false });
    paymentErrorModal.action();
  };

  return (
    <div className={`dialog ${isActive ? 'is-active' : ''} feedback-dialog`}>
      <div className="dialog__frame">
        <div className="dialog__header">
          <div className="dialog__close" onClick={handleClose}>
            <CloseIcon className="nav_color_icon" />
          </div>
        </div>

        <div className="dialog__body">
          <div className="feedback-dialog__error">
            <span className="close-icon">{t(locale, 'payment_error')}</span>
          </div>
          {paymentErrorModal.message}
        </div>

        <div className="dialog__footer">
          <div className="feedback-dialog__buttons">
            <a onClick={handleClose} className="feedback-dialog__buttons__close">
              {t(locale, 'close')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
