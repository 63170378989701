import { SiteLocale } from '@graphql/generated';
import t from '@lib/locales';

type Props = {
  locale: SiteLocale;
};

export const Loading = ({ locale }: Props) => {
  return (
    <div className="loading active">
      <div className="loading__body">
        <div className="loader"></div>
        <div className="loader__message">{t(locale, 'loading')}</div>
      </div>
    </div>
  );
};
